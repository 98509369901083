(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sportinfo/sportinfo-content-view/assets/javascripts/content-section-title.js') >= 0) return;  svs.modules.push('/components/sportinfo/sportinfo-content-view/assets/javascripts/content-section-title.js');
"use strict";


const ContentSectionTitle = _ref => {
  let {
    title,
    children,
    theme
  } = _ref;
  if (!title) {
    return children;
  }
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "section-title ".concat(theme.toLocaleLowerCase())
  }, title), children);
};
setGlobal('svs.components.sportinfo.sportinfoContentView.ContentSectionTitle', ContentSectionTitle);

 })(window);